import type {FederatedCredentials} from '../../fedcm';

const navigatorWithDefaultValues = {
  languages: [],
  userAgent: '',
  userLanguage: '',
  credentials: {} as FederatedCredentials,
};

export const isoNavigator =
  typeof navigator === 'undefined' ? navigatorWithDefaultValues : navigator;

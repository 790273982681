import {useCallback} from 'preact/hooks';

import type {AuthorizeErrorEvent, CompletedEvent} from '~/types/event';

import {useDispatchEvent} from './useDispatchEvent';

export const useWindoidMessageHandler = ({
  handleComplete,
  handleError,
  windoidRef,
}: {
  handleComplete: (event: CompletedEvent) => void;
  handleError?: (event: AuthorizeErrorEvent) => void;
  storefrontOrigin?: string;
  windoidRef: React.RefObject<Window>;
}) => {
  const dispatchEvent = useDispatchEvent();

  const handleWindoidMessage = useCallback(
    async (payload: MessageEvent) => {
      switch (payload.data.type) {
        case 'completed':
          handleComplete(payload.data as any);
          dispatchEvent('completed', payload.data, true);
          windoidRef.current?.close();
          break;
        case 'error':
          handleError?.(payload.data as any);
          dispatchEvent('error', payload.data);
          windoidRef.current?.close();
          break;
      }
    },
    [dispatchEvent, handleComplete, handleError, windoidRef],
  );

  return handleWindoidMessage;
};

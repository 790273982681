import {PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT} from '~/constants/authorize';
import type {PayEvents} from '~/types/event';

export interface PostMessageProps {
  contentWindow: Window | null | undefined;
  event: PayEvents;
}

export function postMessage({contentWindow, event}: PostMessageProps) {
  if (!contentWindow) {
    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error('content window undefined for event', event);
    }

    return;
  }

  const eventOrigins = [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT];

  eventOrigins.forEach((origin) => {
    contentWindow.postMessage(event, origin);
  });
}

export interface MessageEventSource {
  isSourceOf: (event: MessageEvent) => boolean;
}

export class IFrameEventSource implements MessageEventSource {
  private readonly _source: HTMLIFrameElement;

  constructor(source: HTMLIFrameElement) {
    this._source = source;
  }

  isSourceOf(event: MessageEvent) {
    return event.source === this._source.contentWindow;
  }
}

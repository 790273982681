import type {BuildAuthorizeUrlParams} from '~/types/authorizeUrlParams';
import {booleanQueryParam} from '~/utils/booleanQueryParam';
import {isoWindow} from '~/utils/window';

export function buildUrlSearchParams({
  analyticsContext,
  analyticsTraceId,
  apiKey,
  avoidSdkSession,
  checkoutRedirectUrl,
  checkoutToken,
  checkoutVersion,
  clientId,
  codeChallenge,
  codeChallengeMethod,
  consentChallenge,
  disableSignUp,
  error,
  experiments,
  flow,
  flowVersion,
  hideCopy,
  isCompactLayout = true,
  isFullView,
  locale,
  loginStart,
  modalCustomized,
  orderId,
  origin,
  personalizeAds,
  prompt,
  placement,
  popUpFeatures,
  popUpName,
  redirectType,
  redirectUri,
  requireVerification,
  responseMode,
  responseType,
  returnUri,
  scope,
  shopId,
  state,
  storefrontDomain,
  transactionParams,
  uxMode,
  uxRole,
  hideButtons,
  hideHeader,
  accentColor,
  darkMode,
}: BuildAuthorizeUrlParams) {
  const signUpEnabled =
    disableSignUp === undefined ? undefined : disableSignUp === false;

  /* eslint-disable @typescript-eslint/naming-convention */
  const baseParams: Record<string, string | undefined> = {
    analytics_context: analyticsContext,
    analytics_trace_id: analyticsTraceId,
    avoid_sdk_session: booleanQueryParam(avoidSdkSession),
    api_key: apiKey,
    checkout_redirect_url: checkoutRedirectUrl,
    checkout_token: checkoutToken,
    checkout_version: checkoutVersion,
    client_id: clientId,
    code_challenge: codeChallenge,
    code_challenge_method: codeChallengeMethod,
    compact_layout: booleanQueryParam(isCompactLayout),
    consent_challenge: booleanQueryParam(consentChallenge),
    'customize-modal': booleanQueryParam(modalCustomized),
    ...(error && {error}),
    ...(experiments && {experiments}),
    flow: flow ? flow.toString() : undefined,
    flow_version: flowVersion,
    full_view: booleanQueryParam(isFullView),
    hide_copy: booleanQueryParam(hideCopy),
    locale,
    ...(loginStart && {login_start: loginStart}),
    order_id: orderId ? orderId.toString() : undefined,
    origin,
    personalize_ads: booleanQueryParam(personalizeAds),
    hide_buttons: booleanQueryParam(hideButtons),
    hide_header: booleanQueryParam(hideHeader),
    accent_color: accentColor,
    dark_mode: booleanQueryParam(darkMode),
    placement,
    pop_up_features: redirectType === 'pop_up' ? popUpFeatures : undefined,
    pop_up_name: redirectType === 'pop_up' ? popUpName : undefined,
    preact: 'true',
    prompt,
    redirect_type: redirectType,
    redirect_uri: redirectUri || isoWindow.location.origin,
    require_verification: booleanQueryParam(requireVerification),
    response_mode: responseMode || 'web_message',
    response_type: responseType || 'id_token',
    ...(returnUri && {return_uri: returnUri}),
    scope: scope || 'openid email profile',
    sign_up_enabled: booleanQueryParam(signUpEnabled),
    shop_id: shopId ? shopId.toString() : undefined,
    state,
    storefront_domain: storefrontDomain,
    target_origin: isoWindow.location.origin,
    transaction_params: transactionParams,
    ux_mode: uxMode,
    ux_role: uxRole,
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  Object.keys(baseParams).forEach(
    (key) => baseParams[key] === undefined && delete baseParams[key],
  );

  return new URLSearchParams(baseParams as Record<string, string>);
}

import type {AuthorizeState, AuthorizeStateAction} from './types';

export function reducer(state: AuthorizeState, action: AuthorizeStateAction) {
  switch (action.type) {
    case 'setAutoOpened':
      return {...state, autoOpened: action.payload};
    case 'setLoaded':
      return {...state, loaded: action.payload};
    case 'setModalDismissible':
      return {...state, modalDismissible: action.payload};
    case 'setModalForceHidden':
      return {...state, modalForceHidden: action.payload};
    case 'setModalVisible':
      return {
        ...state,
        modalVisible: action.payload,
        // Not dismissible until the transition is complete
        ...(action.payload && {modalDismissible: false}),
      };
    case 'setSessionDetected':
      return {...state, sessionDetected: action.payload};
    case 'updateState': {
      let modalDismissible = action.payload.modalDismissible
        ? action.payload.modalDismissible
        : state.modalDismissible;

      if (action.payload.modalVisible) {
        modalDismissible = false;
      }

      return {
        ...state,
        ...action.payload,
        modalDismissible,
      };
    }
    default:
      return state;
  }
}

export const initialState: AuthorizeState = {
  autoOpened: false,
  loaded: false,
  modalDismissible: false,
  modalForceHidden: false,
  modalVisible: false,
  sessionDetected: false,
};

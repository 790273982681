import {classNames} from '@shopify/css-utilities';
import type {ComponentChildren} from 'preact';
import type {HTMLAttributes} from 'preact/compat';
import {forwardRef} from 'preact/compat';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ComponentChildren;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({children, className: classNameProp, disabled, href, onClick}, ref) => {
    const className = classNames(
      'relative m-0 flex w-auto items-center overflow-visible rounded-login-button border-none bg-purple-primary p-0 transition-all hover_enabled_bg-purple-d0 focus-visible_enabled_outline-none focus-visible_enabled_ring focus-visible_enabled_ring-purple-primary-light disabled_opacity-50',
      classNameProp,
    );

    return (
      <button
        className={className}
        disabled={disabled}
        href={href}
        ref={ref}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

import {useCallback} from 'preact/hooks';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import type {CompletedEvent} from '~/types/event';
import {exchangeLoginCookie} from '~/utils/cookieExchange';
import {isoWindow} from '~/utils/window';

export const useCompleteLogin = ({
  storefrontOrigin,
}: {
  storefrontOrigin?: string;
}) => {
  const {notify} = useBugsnag();

  return useCallback(
    async ({loggedIn, shouldFinalizeLogin, redirectUri}: CompletedEvent) => {
      if (loggedIn && shouldFinalizeLogin) {
        await exchangeLoginCookie(storefrontOrigin, notify);
        /**
         * TODO: In the future we will publish an event to shop hub to create a user session.
         *
         * Issue: https://github.com/Shopify/shop-identity/issues/2859
         * Pull request: https://github.com/Shopify/shop-js/pull/2363
         */
      }

      if (redirectUri) {
        isoWindow.location.href = redirectUri;
      }
    },
    [notify, storefrontOrigin],
  );
};
